<template>
  <div class="box">
    <div class="left leftDetail">
      <img class="leftBg" v-if="!id" src="../assets/images/Projects.png">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt" v-if="!id">
          Projects
        </div>
      </div>
    </div>
    <div class="right rightDetail" :class="{'detail' : id}">
      <div class="rightTitle" v-if="!id">
        <p>Our business provides exceptionally high standard engineering testing, inspection, certification and consultancy services globally.  We specialize in servicing onshore and offshore steel-based structures that include:</p>
      </div>
      <div class="ul">
        <p>●  Ships</p>
        <p>●  Chemical plant</p>
        <p>●  LNG receiving terminals</p>
        <p>●  Mining equipment</p>
        <p>●  Civil steel structures</p>
        <p>●  Industrial equipment</p>
        <p>●  Pressure vessels and surface pipelines </p>
        <p>●  Process piping</p>
        <p>●  Subsea pipeline fields</p>
      </div>
      <div class="rightUl" v-if="!id">
        <router-link to="/Projects/detail?id=1">ZAWTIKA Development Project</router-link>
        <router-link to="/Projects/detail?id=2">Qatar Offshore Oil Field Development (Jackets and Platforms)</router-link>
        <router-link to="/Projects/detail?id=3">YAMAL Project</router-link>
        <router-link to="/Projects/detail?id=4">SHELL PENGUINS FPSO</router-link>
        <router-link to="/Projects/detail?id=6">GCGV Olefin Facilities Modules Fabrication</router-link>
        <router-link to="/Projects/detail?id=5">Wind Power Facilities Fabrication</router-link>
        <router-link to="/Projects/detail?id=7">LD21-2/16-3 Jacket Fabrication Project</router-link>
        <router-link to="/Projects/detail?id=8">Lingshui17-2 Oil-field Development Project</router-link>
        <router-link to="/Projects/detail?id=9">RDC’s 2nd Line Project</router-link>
        <router-link to="/Projects/detail?id=10">Crystalliser Vessel and Scraper Unit: Installation and Commissioning</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Partners',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.rightUl{
  padding-top: 30px;
}
</style>
